import { Injectable } from '@angular/core';
import { BankAccountsData, PurchaseData } from '@interfaces';
import { Store } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxLinksModel,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { MonkeyUtils } from 'monkey-style-guide';

@Injectable()
export class PurchasesDetailsBankAccountsService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    public store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private mappingData(data: BankAccountsData, identifier: string) {
    const actionId: string = new MonkeyEcxLinksModel(data)?.getAction('self')?.href;
    const id = actionId?.split('/')?.pop();

    return {
      ...data,
      identifier,
      id: data.id || id
    };
  }

  private updateControl(data: any) {
    this.store.dispatch(actions.purchasesDetailsBankAccounts.updateControl({ data }));
  }

  private update(data: BankAccountsData[], identifier: string) {
    this.store.dispatch(
      actions.purchasesDetailsBankAccounts.updateAll({
        data: data
          .filter((_) => {
            return _?.account;
          })
          .map((_) => {
            return this.mappingData(_, identifier);
          })
      })
    );
  }

  private getPromise(data: PurchaseData, action: string) {
    const url = new MonkeyEcxLinksModel(data).getAction(action)?.href;

    return this.monkeyecxService
      .get<BankAccountsData>(url)
      .toPromise()
      .catch(() => {
        return null;
      });
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(data: PurchaseData) {
    this.updateControl({ isLoading: true });

    const { sellerBank, sellerAgency, sellerAccount, sellerAccountDigit, identifier } = data;

    const sellerBankData: BankAccountsData = {
      account: sellerAccount,
      accountDigit: sellerAccountDigit,
      agency: sellerAgency,
      bank: sellerBank,
      bankName: '',
      type: 'SELLER',
      identifier,
      id: MonkeyUtils.getRandomString(8)
    };

    const bankAccounts = [sellerBankData];

    try {
      const [receivementData, paymentData] = await Promise.all([
        this.getPromise(data, 'receivement-account'),
        this.getPromise(data, 'payment-account')
      ]);

      bankAccounts.push(receivementData, paymentData);
    } catch (e) {
      // not to do
    }

    this.update(bankAccounts, identifier);

    this.updateControl({ isLoading: false });
  }

  public load(args: any) {
    const { data } = args;

    this.store.dispatch(actions.purchasesDetailsBankAccounts.clear({ identifier: '' }));

    this.loadData(data);
  }
}
